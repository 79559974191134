<template>
  <div>
    <v-breadcrumbs :items="breadcrumb" divider=">" class="pl-0 pt-0" />
    <v-card>
      <div v-if="loading" class="text-center">
        {{ $t('account.getting_info') }}
        <br />
        <v-progress-circular indeterminate color="primary" />
      </div>

      <v-row no-gutters>
        <v-col cols="12" md="4">
          <div class="d-flex align-center justify-center h-100 gradient">
            <svg-icon icon="change-password-background" class="pa-4" />
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <div class="pa-4 pa-md-6">
            <form class="mb-10">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <BaseFormGroup :name="$t('account.timezone')" noGutter>
                    <template>
                      <BaseLabel :title="$t('account.timezone')" />
                      <v-autocomplete
                        v-model="form.timezone"
                        outlined
                        dense
                        hide-details="auto"
                        placeholder="Select Timezone"
                        :items="timezoneItem"
                        @input="$v.form.timezone.$touch()"
                      />
                    </template>
                  </BaseFormGroup>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <BaseFormGroup :name="$t('account.language')" noGutter>
                    <template>
                      <BaseLabel :title="$t('account.language')" />
                      <v-select
                        :items="languageItem"
                        v-model="form.language"
                        placeholder="Select Language"
                        @input="$v.form.language.$touch()"
                        outlined
                        dense
                        hide-details="auto"
                      />
                    </template>
                  </BaseFormGroup>
                </v-col>
              </v-row>
            </form>
            <v-btn class="mr-1 indigo white--text no-transform" @click="patchAccountProfile" :loading="buttonLoading">
              {{ $t('account.save') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <template>
      <v-dialog persistent v-model="showAuthDialog" max-width="500">
        <v-card class="px-10">
          <div class="pt-10">
            <v-img src="../../assets/images/success-icon.png" class="dialog-image" />
          </div>
          <div class="text-center text-h5 font-weight-bold pt-3 dialog-title">
            {{ $t('form.new_password_top') }}
          </div>
          <div class="text-center pb-8 pt-2 dialog-body">
            {{ $t('form.change_account_setting') }}
          </div>
          <div class="text-center pb-8">
            <v-btn color="primary" class="dialog-button" @click="goToSetting">
              {{ $t('content.ok') }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { TIMEZONES as timezones } from '../../constant/timezone.constant';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'AccountTimeAndLang',
  data: () => ({
    form: {
      timezone: 'Africa/Abidjan',
      language: 'en',
    },
    timezoneItem: timezones,
    languageItem: [
      { text: 'English', value: 'en' },
      { text: 'Japanese', value: 'ja' },
      { text: 'Mandarin (Simplified)', value: 'zh-hans' },
      { text: 'Mandarin (Traditional)', value: 'zh-hant' },
    ],
    showAuthDialog: false,
    loading: true,
    buttonLoading: false,
  }),
  validations: {
    form: {
      timezone: { required },
      language: { required },
    },
  },
  created() {
    this.getAccountProfile();
  },
  computed: {
    breadcrumb() {
      return [
        { text: this.$t('account.account_setting'), to: { name: 'AccountSetting' } },
        { text: this.$t('account.change_account_time_and_lang'), disabled: true },
      ];
    },
  },
  methods: {
    getAccountProfile() {
      const userId = this.$store.getters['Auth/user'].account_id;
      this.$store
        .dispatch('Auth/getAccountSetting', userId)
        .then(res => {
          this.form.language = res.language;
          this.form.timezone = res.timezone;
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.loading = false));
    },
    patchAccountProfile() {
      this.buttonLoading = true;
      const data = {
        id: this.$store.getters['Auth/user'].account_id,
        form: {
          timezone: this.form.timezone,
          language: this.form.language,
        },
      };
      this.$store
        .dispatch('Auth/patchAccountSetting', data)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.showAuthDialog = true;
          this.$i18n.locale = this.form.language;
          localStorage.setItem('lang', this.form.language);
          localStorage.setItem('timezone', this.form.timezone);
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          this.buttonLoading = false;
        });
    },
    goToSetting() {
      this.$router.push({ name: 'AccountSetting' }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-image {
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
.dialog-body {
  font-size: 15px;
  color: #16325ccc;
}
.dialog-title {
  color: #16325c;
}
.dialog-button {
  width: 100px;
}
.gradient {
  background: rgb(0, 96, 175);
  background: linear-gradient(
    0deg,
    rgba(0, 96, 175, 0.6001751042213761) 0%,
    rgba(176, 210, 237, 0.569362779291404) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}
</style>
